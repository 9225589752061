/*
 * @Author: 祁明华 7681539@qq.com
 * @Date: 2024-03-27 10:48:03
 * @LastEditors: 祁明华 7681539@qq.com
 * @LastEditTime: 2024-05-10 16:34:06
 * @FilePath: \深百基地（PC端）\src\api\wxConfig\index.js
 * @Description: 微信配置API
 *
 */
import qs from "qs";
import axios from "../http";

export function addTemplate(data) {
  return axios.post("/template/add", data);
}

export function updateTemplate(data) {
  return axios.post("/template/update", data);
}
//微信公众号保存（特殊）
export function saveTemplateWx(data) {
  return axios.post("/template/saveWechat", data);
}

export function delTemplate(data) {
  return axios.post("/template/del", qs.stringify(data));
}

export function listTemplate(data) {
  return axios.post("/template/list", qs.stringify(data));
}

export function resetTemplate(data) {
  return axios.post("/template/reset", data);
}

export function getTemplateName(data) {
  return axios.post("/template/getTemplateName", qs.stringify(data));
}

export function updateTemplateName(data) {
  return axios.post("/template/updateTemplateName", qs.stringify(data));
}

export function getTemplateDetail(params) {
  return axios.get("/template/get", {
    params,
  });
}

export function getMemberDetail(params) {
  return axios.get("/template/getMember", {
    params,
  });
}

//新增推文
export function apiTweetAdd(data) {
  return axios.post("/tweet/add", data);
}
//根据id删除推文
export function apiTweetDel(data) {
  return axios.post("/tweet/del", qs.stringify(data));
}
//获取推文列表
export function apiTweetList(data) {
  return axios.post("/tweet/list", qs.stringify(data));
}
//根据id列表,重新排序(推文)
export function apiTweetReset(data) {
  return axios.post("/tweet/reset", qs.stringify(data));
}
//更新推文
export function apiTweetUpdate(data) {
  return axios.post("/tweet/update", data);
}

//查看推文
export function apiTweetGet(params) {
  return axios.get("/tweet/get", {
    params,
  });
}

import axios from "../http";
import qs from "qs";

/**
 * 相册分类管理
 */

export function addAlbumType(data) {
  return axios.post("/photoAlbumType/add", qs.stringify(data));
}

export function delAlbumType(data) {
  return axios.post("/photoAlbumType/del", qs.stringify(data));
}

export function resetAlbumType(data) {
  return axios.post("/photoAlbumType/reset", data);
}

export function updateAlbumType(data) {
  return axios.post("/photoAlbumType/update", qs.stringify(data));
}

export function getAlbumType(params) {
  return axios.get("/photoAlbumType/selectTypeList", {
    params,
  });
}

/**
 * 相册管理
 */

export function addAlbum(data) {
  return axios.post("/photoAlbum/add", data);
}

export function delAlbum(data) {
  return axios.post("/photoAlbum/del", qs.stringify(data));
}

export function updateAlbum(data) {
  return axios.post("/photoAlbum/update", data);
}

export function listAlbum(data) {
  return axios.post("/photoAlbum/list", qs.stringify(data));
}

export function resetAlbum(data) {
  return axios.post("/photoAlbum/reset", data);
}

export function getAlbumDetail(params) {
  return axios.get("/photoAlbum/get", {
    params,
  });
}

/**
 * 相片管理
 */

export function addPhoto(data) {
  return axios.post("/photo/add", data);
}

export function delPhoto(data) {
  return axios.post("/photo/del", qs.stringify(data));
}

export function resetPhoto(data) {
  return axios.post("/photo/reset", data);
}

export function selectPhoto(data) {
  return axios.post("/photo/select", qs.stringify(data));
}

/*
 * @Author: 祁明华 7681539@qq.com
 * @Date: 2024-01-22 13:27:55
 * @LastEditors: 祁明华 7681539@qq.com
 * @LastEditTime: 2024-04-28 14:37:16
 * @FilePath: \batar-apartment-web\src\api\http.js
 * @Description: 请求配置
 */
import router from "@/router";
import axios from "axios";
import { Notification } from "element-ui";

// http://192.168.16.25:31035
// 192.168.33.52:8081

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://192.168.51.44:8082"
    : "https://sbgy.batargold.com/api";

const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    contentType: "application/x-www-form-urlencoded",
  },
});

const errorMessageMap = new Map([
  [400, "无效请求"],
  [401, "未授权(401)"],
  [403, "拒绝访问（403）"],
  [404, "请求出错(404)"],
  [408, "请求超时（408）"],
  [500, "服务器错误（500）"],
  [501, "服务未实现（501）"],
  [502, "网络错误（502）"],
  [503, "服务不可用（503）"],
  [504, "网络超时（504）"],
  [505, "HTTP版本不受支持（505）"],
  [999999, "系统错误, 请联系管理员！"],
]);

function notifiFunc(statusCode, message, errorType = "error") {
  Notification({
    title: errorType === "error" ? "错误" : "警告",
    message: message || errorMessageMap.get(statusCode),
    type: errorType === "error" ? "error" : "warning",
    position: "top-left",
  });
}

// request拦截器
instance.interceptors.request.use(
  (config) => {
    config.headers.accessToken = `${localStorage.accessToken || ""}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    if (error && error.response) {
      const errorResponse = error.response;
      const code = errorResponse.status;
      if (error.response.data === "未登录") {
        localStorage.clear();
        router.replace({ path: "/login" });
      }
      // if (code === 403) {
      //   MessageBox.confirm(
      //     "登录状态已过期，您可以继续留在该页面，或者重新登录",
      //     "系统提示",
      //     {
      //       confirmButtonText: "重新登录",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     }
      //   ).then(() => {
      //     router.replace({ path: "/login" });
      //     location.reload(); // 重新加载
      //   });
      //   return;
      // }
      notifiFunc(code, errorResponse.data || undefined);
    }
    return Promise.reject(error);
  }
);

export default instance;

import axios from "../http";
import qs from "qs";

// export function login(data) {
//   return axios.post("/account/login", qs.stringify(data));
// }

export function login(data) {
  return axios.post("/user/login", qs.stringify(data));
}

export function loginOut(data) {
  return axios.post("/user/loginOut", qs.stringify(data));
}

import axios from "./http";
import * as common from "./common";
import * as auth from "./auth";
import * as manage from "./activityManagement";
import * as album from "./albumClasfication";
import * as wxConfig from "./wxConfig";

export default {
  axios,
  common,
  auth,
  manage,
  album,
  wxConfig,
};

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/index",
    component: () => import("@/views/Index.vue"),
    redirect: "/index/activityManagement",
    children: [
      {
        path: "activityManagement",
        name: "ActivityManagement",
        component: () =>
          import("@/views/activityManagement/ActivityManagement.vue"),
      },
      {
        path: "albumManagement",
        name: "AlbumManagement",
        component: () => import("@/views/AlbumManagement.vue"),
      },
      {
        path: "wxConfig",
        name: "WxConfig",
        component: () => import("@/views/WxConfig.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

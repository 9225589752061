import axios from "../http";
import qs from "qs";

export function add(data) {
  return axios.post("/event/add", data);
}

export function list(data) {
  return axios.post("/event/list", data);
}

export function update(data) {
  return axios.post("/event/update", data);
}

export function updateStatus(data) {
  return axios.post("/event/updateStatus", qs.stringify(data));
}

/**
 * 活动列表拖动排序
 */
export function reset(data) {
  return axios.post("/event/reset", data);
}

export function get(params) {
  return axios.get("/event/get", {
    params,
  });
}

export function top(params) {
  return axios.get("/event/top", {
    params,
  });
}

export function getOrderData(params) {
  return axios.get("/event/getOrderData", {
    params,
  });
}

export function verifyDelEventGroup(params) {
  return axios.get("/event/verifyDelEventGroup", {
    params,
  });
}

export function updateUsersStatus(data) {
  return axios.post("/order/updataStatus", qs.stringify(data));
}

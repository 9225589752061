import Element from "element-ui";
import Vue from "vue";
import App from "./App.vue";
import axios from "./api/";
import "./assets/css/global.css";
import "./assets/css/mini-reset.css";
import "./assets/scss/index.scss";
import router from "./router";
import store from "./store";

// import "~element-ui/packages/theme-chalk/src/index";
Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

// Vue.prototype.$IMG_URL = "http://192.168.16.25:31035/common/showPic?adress=";



Vue.prototype.$IMG_URL =
  process.env.NODE_ENV === "development"
    ? "http://192.168.51.44:8082/common/showPic?adress="
    : "https://sbgy.batargold.com/api/common/showPic?adress=";

Vue.prototype.$THUMB_Url =
  process.env.NODE_ENV === "development"
    ? "http://192.168.51.44:8082/common/thumbnailImage?adress="
    : "https://sbgy.batargold.com/api/common/thumbnailImage?adress=";

Vue.prototype.$VIDEO_URL =
  process.env.NODE_ENV === "development"
    ? "http://192.168.51.44:8082/common/videoShow?adress="
    : "https://sbgy.batargold.com/api/common/videoShow?adress=";

Vue.use(Element, { size: "mini" });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
